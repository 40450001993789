<template>
    <div :class="[type == 'replay' ? '' : 'rounded']" @click="playPauseAction">
        <svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" id="play" v-if="type == 'play'">
            <circle cx="30" cy="30" r="30" fill="#00000050"/>
            <path d="M39.9569 31.9304L25.4275 41.5826C24.6824 42.0974 23.9248 42.136 23.1549 41.6984C22.385 41.2609 22 40.5788 22 39.6522V20.3478C22 19.4212 22.385 18.7391 23.1549 18.3016C23.9248 17.864 24.6824 17.9026 25.4275 18.4174L39.9569 28.0696C40.6523 28.5329 41 29.1763 41 30C41 30.8237 40.6523 31.4671 39.9569 31.9304Z" fill="#FDE7F1"/>
        </svg>
        <svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" id="pause" v-if="type == 'pause'">
            <path d="M24 42C24.85 42 25.5625 41.7125 26.1375 41.1375C26.7125 40.5625 27 39.85 27 39V21C27 20.15 26.7125 19.4375 26.1375 18.8625C25.5625 18.2875 24.85 18 24 18C23.15 18 22.4375 18.2875 21.8625 18.8625C21.2875 19.4375 21 20.15 21 21V39C21 39.85 21.2875 40.5625 21.8625 41.1375C22.4375 41.7125 23.15 42 24 42ZM36 42C36.85 42 37.5625 41.7125 38.1375 41.1375C38.7125 40.5625 39 39.85 39 39V21C39 20.15 38.7125 19.4375 38.1375 18.8625C37.5625 18.2875 36.85 18 36 18C35.15 18 34.4375 18.2875 33.8625 18.8625C33.2875 19.4375 33 20.15 33 21V39C33 39.85 33.2875 40.5625 33.8625 41.1375C34.4375 41.7125 35.15 42 36 42ZM30 60C25.85 60 21.95 59.2125 18.3 57.6375C14.65 56.0625 11.475 53.925 8.775 51.225C6.075 48.525 3.9375 45.35 2.3625 41.7C0.7875 38.05 0 34.15 0 30C0 25.85 0.7875 21.95 2.3625 18.3C3.9375 14.65 6.075 11.475 8.775 8.775C11.475 6.075 14.65 3.9375 18.3 2.3625C21.95 0.7875 25.85 0 30 0C34.15 0 38.05 0.7875 41.7 2.3625C45.35 3.9375 48.525 6.075 51.225 8.775C53.925 11.475 56.0625 14.65 57.6375 18.3C59.2125 21.95 60 25.85 60 30C60 34.15 59.2125 38.05 57.6375 41.7C56.0625 45.35 53.925 48.525 51.225 51.225C48.525 53.925 45.35 56.0625 41.7 57.6375C38.05 59.2125 34.15 60 30 60Z" fill="#00000050"/>
            <path d="M24 42C24.85 42 25.5625 41.7125 26.1375 41.1375C26.7125 40.5625 27 39.85 27 39V21C27 20.15 26.7125 19.4375 26.1375 18.8625C25.5625 18.2875 24.85 18 24 18C23.15 18 22.4375 18.2875 21.8625 18.8625C21.2875 19.4375 21 20.15 21 21V39C21 39.85 21.2875 40.5625 21.8625 41.1375C22.4375 41.7125 23.15 42 24 42ZM36 42C36.85 42 37.5625 41.7125 38.1375 41.1375C38.7125 40.5625 39 39.85 39 39V21C39 20.15 38.7125 19.4375 38.1375 18.8625C37.5625 18.2875 36.85 18 36 18C35.15 18 34.4375 18.2875 33.8625 18.8625C33.2875 19.4375 33 20.15 33 21V39C33 39.85 33.2875 40.5625 33.8625 41.1375C34.4375 41.7125 35.15 42 36 42Z" fill="#FFFFFF"/>
        </svg>
        <svg width="44" height="47" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg" id="replay" v-if="type == 'replay'">
            <path d="M11.5663 26.57C10.016 26.5811 8.47984 26.2748 7.05233 25.67C4.27665 24.501 2.06832 22.2926 0.899333 19.517C0.294447 18.0884 -0.0115061 16.5512 0.000330792 15C0.000330792 14.7443 0.101894 14.4991 0.282679 14.3183C0.463464 14.1375 0.708659 14.036 0.964328 14.036C1.22 14.036 1.4652 14.1375 1.64598 14.3183C1.82677 14.4991 1.92833 14.7443 1.92833 15C1.92833 17.5562 2.94381 20.0078 4.75138 21.8154C6.55895 23.623 9.01054 24.6385 11.5668 24.6385C14.1231 24.6385 16.5747 23.623 18.3823 21.8154C20.1898 20.0078 21.2053 17.5562 21.2053 15C21.2303 13.7371 21.0014 12.4822 20.5323 11.3095C20.0631 10.1368 19.3633 9.07018 18.4743 8.17296C17.6077 7.26014 16.5598 6.53854 15.3979 6.05446C14.236 5.57038 12.9857 5.33451 11.7273 5.36196H11.0203L12.6913 7.03296C12.7822 7.12103 12.8546 7.22629 12.9043 7.34261C12.9541 7.45893 12.9802 7.58398 12.9812 7.7105C12.9822 7.83701 12.9579 7.96244 12.91 8.07952C12.862 8.19659 12.7913 8.30295 12.7018 8.39241C12.6123 8.48187 12.506 8.55265 12.3889 8.60062C12.2718 8.64858 12.1464 8.67277 12.0199 8.6718C11.8934 8.67083 11.7683 8.6447 11.652 8.59494C11.5357 8.54518 11.4304 8.47279 11.3423 8.38196L7.96933 5.00896C7.87806 4.92192 7.80539 4.81727 7.75575 4.70133C7.7061 4.58539 7.68051 4.46058 7.68051 4.33446C7.68051 4.20834 7.7061 4.08353 7.75575 3.96759C7.80539 3.85165 7.87806 3.747 7.96933 3.65996L11.3753 0.253954C11.5522 0.0906369 11.7854 0.00210107 12.0261 0.00689918C12.2669 0.0116973 12.4964 0.109459 12.6666 0.279696C12.8368 0.449933 12.9346 0.679434 12.9394 0.920138C12.9442 1.16084 12.8556 1.39407 12.6923 1.57096L10.8293 3.43396H11.5683C13.1186 3.42285 14.6548 3.72913 16.0823 4.33396C18.858 5.50294 21.0663 7.71127 22.2353 10.487C22.8292 11.9178 23.1349 13.4518 23.1349 15.001C23.1349 16.5502 22.8292 18.0841 22.2353 19.515C21.0663 22.2906 18.858 24.499 16.0823 25.668C14.6544 26.2737 13.1174 26.5807 11.5663 26.57Z" fill="white"/>
            <path d="M10 12.544V17.6799L14.0393 15.1094L10 12.544ZM9.2566 10.4326C9.39384 10.4326 9.53495 10.4707 9.66595 10.5548L15.8288 14.4691C16.2982 14.7719 16.2982 15.4533 15.8288 15.7486L9.66595 19.6704C9.15868 19.996 8.5 19.6326 8.5 19.0344V11.1908C8.5 10.7471 8.8624 10.4326 9.2566 10.4326Z" fill="white"/>
        </svg>    
    </div>
</template>
<script>
    export default {
        props: ['type', 'action'],
        methods: {
            playPauseAction() {
                this.$emit('action', this.type);
            }
        }
    }
</script>
<style scoped lang="scss">
    .rounded {
        border: 5px solid #FFFFFF;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
    }
    .rounded:hover {
        opacity: 1;
        transform: scale(1.25);
    }
</style>